<template>
  <EmIconButton
    icon="mdi-content-copy"
    size="small"
    :title="`Copy ${title.toLowerCase()}`"
    :color="color"
    @click.stop="copy"
  />
</template>
<script setup>
const props = defineProps({
  text2copy: { type: [String, Number], required: true },
  title: { type: String, default: null },
  color: { type: String, default: 'primary' }
})
const { copyToClipboard } = useDeviceStore()
const copy = async () => {
  await copyToClipboard({ value: props.text2copy, text: props.title })
}
</script>
